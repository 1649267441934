import React, { useState, useEffect } from 'react';
import './YTAgentComponent.css';
import { run_youtube_agent ,getQueuedJob,resetQueuedJob, PaymentChoiceStatus, run_rss_agent, getFinalPrompt } from '../../common/nip105-client';
import { BeatLoader } from 'react-spinners';
import TranscriptModal from '../../common/components/modal/TranscriptModal';
import RSSAgentIcon from '../../media/images/services/rss-agent.png';
import FountainLogo from "../../media/images/misc/fountainLogo.png"
import CASCDRLogo from "../../media/images/misc/cascdr-no-bg-just-waterfall.png"
import PlaceholderImage from '../../media/images/misc/cascdr-placeholder.png';
import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';
import { fetchAndParseFeeds, fetchAndParseFeedData, getFountainLink} from '../../lib/rssHelpers';
import PromptOptionsComponent from '../../common/PromptOptionsComponent.jsx';

const YTAgentComponent = ({paymentChoiceStatus, setPaymentChoiceStatus}) => {
  
  const [podcastInput, setPodcastInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [feeds, setFeeds] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [episodeModalOpen, setEpisodeModalOpen] = useState(false);
  const [transcript, setTranscript] = useState('');
  const { activeTab, setActiveTab } = useActiveTab();
  const [title, setTitle] = useState('');

  useEffect(() => {
    const staticFeeds = [
      {
        id: 6786106,
        title: "The Joe Rogan Experience",
        url: "https://feeds.megaphone.fm/GLT1412515089",
        originalUrl: "https://anchor.fm/s/308e8de0/podcast/rss",
        link: "",
        description: "The official podcast of comedian Joe Rogan.",
        author: "Joe Rogan",
        ownerName: "Joe Rogan",
        image: "https://megaphone.imgix.net/podcasts/8e5bcebc-ca16-11ee-89f0-0fa0b9bdfc7c/image/c2c595e6e3c2a64e6ea18fb6c6da8860.jpg"
      },
      {
        id: 1000839,
        title: "Bitcoin Audible",
        url: "https://feeds.castos.com/mj96z",
        originalUrl: "https://anchor.fm/s/80d5cfc/podcast/rss",
        link: "https://bitcoinaudible.com/",
        description: "The Best in Bitcoin made Audible.",
        author: "Guy Swann",
        ownerName: "Guy Swann",
        image: "https://episodes.castos.com/6626b866f2af87-36468692/images/podcast/covers/c1a-9mg94-o87n4gnwav1j-6d2xb6.jpg"
      },
      {
        id: 3955537,
        title: "Thriller \"A Bitcoin Zine\"",
        url: "https://feeds.transistor.fm/thriller-premium",
        originalUrl: "https://api.substack.com/feed/podcast/9895.rss",
        link: "https://www.thrillerbitcoin.com",
        description: "Thriller is a local austin bitcoin zine. | Listen to the pod http://thriller.transistor.fm | ⚡️thriller@getalby.com",
        author: "Thriller X Recordings",
        ownerName: "Thriller X Recordings",
        image: "https://img.transistor.fm/flZJC8zviqt7OAbY5RXG072wag-t6IvdBZKEzHUCfgI/rs:fill:3000:3000:1/q:60/aHR0cHM6Ly9pbWct/dXBsb2FkLXByb2R1/Y3Rpb24udHJhbnNp/c3Rvci5mbS9zaG93/LzIzMjQwLzE2NjQ1/NTE2NjItYXJ0d29y/ay5qcGc.jpg"
      },
      {
        id: 726364,
        title: "Bitcoin Fixes This",
        url: "https://anchor.fm/s/2a4e8034/podcast/rss",
        originalUrl: "https://anchor.fm/s/2a4e8034/podcast/rss",
        link: "https://programmingbitcoin.com",
        description: "We explore the impact that Bitcoin will have in all aspects of society.",
        author: "Jimmy Song",
        ownerName: "Jimmy Song",
        image: "https://d3t3ozftmdmh3i.cloudfront.net/production/podcast_uploaded_nologo/6997877/6997877-1594782510386-e2bbc8c35dd3d.jpg"
      },
      {
        id: 1143799,
        title: "The Bitcoin Standard Podcast",
        url: "https://feeds.buzzsprout.com/1849151.rss",
        originalUrl: "https://www.spreaker.com/show/3478385/episodes/feed",
        link: "https://saifedean.com/podcast/",
        description: "Saifedean's The Bitcoin Standard Podcast is the place to discuss Bitcoin and economics from the Austrian school's perspective.",
        author: "Dr. Saifedean Ammous",
        ownerName: "Dr. Saifedean Ammous",
        image: "https://storage.buzzsprout.com/m4cl5ql1mdv17q3ktf94m0ookj0d?.jpg"
      },
      {
        id: 5738301,
        title: "Blue Collar Bitcoin",
        url: "https://feed.podbean.com/bluecollarbitcoinpodcast/feed.xml",
        originalUrl: "https://feed.podbean.com/bluecollarbitcoinpodcast/feed.xml",
        link: "https://bluecollarbitcoinpodcast.podbean.com",
        description: "Firefighters explore economics, finance, & the most important monetary technology of the 21st century—Bitcoin.",
        author: "Blue Collar Bitcoin Podcast",
        ownerName: "Blue Collar Bitcoin Podcast",
        image: "https://pbcdn1.podbean.com/imglogo/image-logo/11936639/Podcast_Logo_622237paty.png"
      }
    ];
    console.log(`initial load setFeeds`)
    setFeeds(staticFeeds);
  }, []);
  

  useEffect(() => {
    try{
      console.log("paymentChoiceStatus changed:",paymentChoiceStatus)
      if(paymentChoiceStatus === null){
        resetQueuedJob();
      }
      else if (paymentChoiceStatus === PaymentChoiceStatus.DONE){
        const data = getQueuedJob().requestData;
        performJob(data);
      }
    }
    catch{
      console.log("issue with resetting queue or performing queued job")
    }
  }, [paymentChoiceStatus]);

  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search);
      const tab = params.get('tab');
      if (tab) {
        setActiveTab(tab);
      } else {
        setActiveTab(null);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
  }, [setActiveTab]);

  useEffect(() => {
    if (episodes?.length > 0) handleShowEpisodeModal();
  }, [episodes]);

  useEffect(() => {
    const fetchFeeds = async () => {
      if (podcastInput) {
        try {
          const feedsData = await fetchAndParseFeeds(podcastInput);
          setFeeds(feedsData);
          console.log("feeds:", feedsData);
        } catch (error) {
          console.error('Error fetching feeds:', error);
        }
      }
    };
  
    fetchFeeds();
  }, [podcastInput]);

  const handleShowEpisodeModal = () => {
    setEpisodeModalOpen(true);
  };

  const handleCloseEpisodeModal = () => {
    setEpisodeModalOpen(false);
  };

  const handleSelectPodcast = async (feedId, feedUrl) => {
    // Implement the logic to handle the podcast selection
    console.log('Selected podcast:', feedId, feedUrl);
    setIsLoading(true);
    const fetchEpisodes = async () => {
      try {
        const episodesData = await fetchAndParseFeedData(feedUrl, feedId);
        setEpisodes(episodesData);
      } catch (error) {
        console.error('Error fetching episodes:', error);
      }
    }
    await fetchEpisodes();
    setIsLoading(false);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // Handle success
    }).catch((err) => {
      console.error('Error copying to clipboard:', err);
    });
  };

  async function performJob(data) {
    setIsLoading(true);
    try {
      const result = await run_rss_agent(data);
      if (result.authCategory && result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
      } else {
        setTranscript(result.transcript);
        setIsTranscriptModalOpen(true);
      }
    } catch (error) {
      console.error('Error running YouTube agent:', error);
      alert('Failed to process the request. Please try again.');
    }
    setIsLoading(false);
  }

  return (
    <div className="yitter-container">
      <div className="yt-header-container">
        <div className="yt__icon">
          <img src={RSSAgentIcon} alt="icon" />
        </div>
        <h1 className="yt-title">RSS Agent: Write Articles & Summaries</h1>
      </div>
 
      {isTranscriptModalOpen && (
        <TranscriptModal
          title={title ? title : "Summary"}
          transcript={transcript}
          onClose={() => setIsTranscriptModalOpen(false)}
          onCopy={handleCopyToClipboard}
        />
      )}
          <DebouncedInput onChange={setPodcastInput}/>
          {feeds?.length > 0 ? (
            <div className="feeds-container">
              <FeedList feeds={feeds} handleSelectPodcast={handleSelectPodcast} isLoading={isLoading} />
            </div>
          ) : ( null )}

          {feeds.length === 0 && podcastInput && !isLoading && (<div className="yitter-podcasts-empty">No podcasts found.</div>)}

          {episodeModalOpen && (
            <FeedDataModal
              isVisible={episodeModalOpen}
              onClose={handleCloseEpisodeModal}
              episodes={episodes}
              performJob={performJob}
            />
          )} 
    </div>
  );
};

const DebouncedInput = ({podcastInput, onChange}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    if (value) {
      const handler = setTimeout(() => {
      console.log('debounced input:', value)
      console.log("podcastInput:",podcastInput)
      onChange(value);
    }, 1000); // 1 second debounce

    return () => {
      clearTimeout(handler);
    };
    }
  }, [value, onChange]);

  return (
    <input
      type="text"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Start typing to search for a podcast to analyze..."
      className="yitter-input"
    />
  );
};


const FeedDataModal = ({ isVisible, onClose, episodes, performJob }) => {
  const [option, setOption] = useState('Summary');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedEpisodeUrl, setSelectedEpisodeUrl] = useState('');
  const [customPrompt, setCustomPrompt] = useState('');

  const handleOptionChange = (newOption) => {
    setOption(newOption);
  };

  const handleCustomPromptChange = (newPrompt) => {
    setCustomPrompt(newPrompt);
  };


  const handleJob = async ({ episodeItemUrl, episodeGUID }) => {
    const { promptText, endpoint } = getFinalPrompt(option, customPrompt);
    const data = {
      ytLink: 'episode mode',
      customPrompt: promptText,
      type: endpoint,
      episodeItemUrl,
      guid: episodeGUID
    };

    await performJob(data);
    setIsLoading(false);
  };

  const handleEpisodeClick = (episodeUrl, episodeGUID) => {
    setIsLoading(true);
    setSelectedEpisodeUrl(episodeUrl);
    handleJob({ episodeItemUrl: episodeUrl, episodeGUID: episodeGUID });
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <PromptOptionsComponent
            onOptionChange={handleOptionChange}
            onCustomPromptChange={handleCustomPromptChange}
          />
        </div>
        <h1 className="yt-title">RSS Agent: Write Articles & Summaries</h1>

        <div className="episodes-container">
          {episodes?.length > 0 ? (
            <div className="episodes-list">
              {episodes.map((episode) => (
                <EpisodeItem
                  key={episode.itemUUID}
                  episode={episode}
                  isLoading={isLoading}
                  handleEpisodeClick={handleEpisodeClick}
                />
              ))}
            </div>
          ) : (
            <div className="yitter-podcasts-empty">No episodes found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

const EpisodeItem = ({ episode, isLoading, handleEpisodeClick }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isListenLoading, setIsListenLoading] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = episode.episodeImage;
    img.onload = () => setIsImageLoaded(true);
    console.log("got episode data:", episode);
  }, [episode.episodeImage]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const handleListenClick = async (guid) => {
    setIsListenLoading(true);
    try {
      const fountainLink = await getFountainLink(guid);
      console.log(`retrieved fountainLink:`, fountainLink)
      const finalLink = fountainLink ? fountainLink : guid
      window.open(finalLink, '_blank');
    } catch (error) {
      console.error('Error opening Fountain link:', error);
    } finally {
      setIsListenLoading(false);
    }
  };

  return (
    <div className="episode-item">
      <img
        src={isImageLoaded ? episode.episodeImage : PlaceholderImage}
        alt={episode.itemTitle}
        title={episode.itemTitle}
        className="episode-image"
      />
      <div className="episode-info">
        <div className="episode-details">
          <h3 className="episode-title">{episode.itemTitle}</h3>
          <p className="episode-creator">Creator: {episode.creator}</p>
          <p className="episode-date">Published: {formatDate(episode.publishedDate)}</p>
          <p className="episode-description">
            {episode.description?.length > 400
              ? `${episode.description.substring(0, 400)}...`
              : episode.description}
          </p>
        </div>
        <div className="episode-button-container">
          <button
            className="episode-button"
            onClick={() => handleEpisodeClick(episode.itemUrl, episode.episodeGUID)}
            disabled={isLoading}
          >
            {isLoading ? (
              <BeatLoader color="#fff" loading={true} size={8} />
            ) : (
              <>
                <img src={CASCDRLogo} alt="Analyze" />
                Analyze
              </>
            )}
          </button>
          <button
            className="listen-button"
            onClick={() => handleListenClick(episode.episodeGUID)}
          >
            {isListenLoading ? (
              <BeatLoader color="#333" loading={true} size={8} />
            ) : (
              <>
                <img src={FountainLogo} alt="Listen" />
                Listen
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};


const FeedItem = ({ feed, handleSelectPodcast, isLoading }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = feed.image;
    img.onload = () => setIsImageLoaded(true);
  }, [feed.image]);

  return (
    <div key={feed.id} className="feed-item">
      <img
        src={isImageLoaded ? feed.image : PlaceholderImage}
        alt={feed.title}
        title={feed.title}
        className="feed-image"
      />
      <div className="feed-info">
        <div className="feed-podcast-name">{feed.podcastName}</div>
        <div className="feed-creator">{feed.creator}</div>
        <div className="feed-title">{feed.title}</div>
        <p className="feed-description">{feed.description}</p>
      </div>
      <button
        onClick={() => handleSelectPodcast(feed.id, feed.url)}
        className="select-podcast-button"
        disabled={isLoading}
      >
        {isLoading ? <BeatLoader color="white" loading={true} size={8} /> : 'Select'}
      </button>
    </div>
  );
};

const FeedList = ({ feeds, handleSelectPodcast, isLoading }) => {
  return (
    <div>
      {feeds.map(feed => (
        <FeedItem
          key={feed.id}
          feed={feed}
          handleSelectPodcast={handleSelectPodcast}
          isLoading={isLoading}
        />
      ))}
    </div>
  );
};





export default YTAgentComponent;
